<template>
  <div class="news">
    <Header activeTit="新闻资讯"></Header>
    <div class="banner">
      <img src="./../assets/img/banner.png" alt="">
    </div>
    <div class="content">
      <div class="main">
        <div class="detail">
          <div class="tit">{{checkedItem.title}}</div>
          <div class="date">{{checkedItem.date}}</div>
          <div class="desc">
            <div class="text">{{checkedItem.content}}</div>
            <div class="img">
              <img :src="require('./../assets/img/' + checkedItem.img)" alt="">
            </div>
          </div>
        </div>
        <div class="items" v-for="(v, k) in showNews" :key="k">
          <div :class="[activeIndex == k ? 'active' : '', 'item']" @click="checkItem(k)">
            <div class="l_item">
              <div class="l_top">{{v.order}}</div>
              <div class="l_bottom">{{v.date}}</div>
            </div>
            <div class="r_item">
              <div class="tit">{{v.title}}</div>
              <div class="g_text">
                <div class="text">{{v.content}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
        background
        layout="total, prev, pager, next"
        :total="news.length"
        :current-page="currentPage"
        :page-size="pagesize"
        @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './layout/Header'
import Footer from './layout/Footer'
export default {
  name: 'news',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      news: [
        {
          order: '01',
          date: '2020/7/10',
          img: '新闻图片1.png',
          title: '祝贺三探头中医脉诊仪荣获二类医疗器械注册证',
          content: '2020年7月6日，由帕斯泰克公司自主研发的三探头中医智能脉诊仪（MS3-IV）获得由天津市药品监督管理局颁发的二类医疗器械注册证。此次收到《医疗器械注册证》的产品名为三探头中医脉诊仪，主要用于对成年人群进行人体脉诊信息采集。此次三探头中医脉诊仪《医疗器械注册证》的取得，有助于满足市场多样化的中医脉诊检测需求，进一步提升公司的核心竞争力与综合服务能力。'
        },
        {
          order: '02',
          date: '2019/10/25',
          img: '秋季博览会.jpg',
          title: '帕斯泰克“第82届中国国际医疗器械（秋季）博览会”圆满收官',
          content: '作为中国最具影响力的展会之一，本届中国国际医疗器械（秋季）博览会”于2019年10月19日至22日在青岛世博城国际展览中心成功举办，本届展会设置14万平方米展览面积,吸引超过3000家品牌企业,奉上20000余领先产品亮相,包括来自德国、美国、韩国、日本、英国、法国、新加坡等近20个国家的国际品牌,以及来自北京、山东、上海、浙江、江西、广东等省市的特色医械产品。 帕斯泰克“三探头中医智能脉诊仪”作为年度高新技术产品之一，也受邀在本次展会展馆中展出，并以其成功模拟中医把脉，实现中医脉诊客观化的体验效果轰动馆内，现代化的便携设计、互联网大数据与传统中医把脉相结合，让来场参观的近万名参观者和体验者为之赞叹！'
        },
        {
          order: '03',
          date: '2019/7/5',
          img: '康复展.jpg',
          title: '帕斯泰克“北京国际康复及个人健康博览会”圆满收官',
          content: '作为中国最具影响力的展会之一，本届北京国际康复及个人展览会于2019年6月28日至30日在北京国家会议中心成功举办，展会共邀请了中国、日本、韩国等多个国家和地区的创新创业企业前来参展。 帕斯泰克“三探头中医智能脉诊仪”作为年度高新技术产品之一，也受邀在本次展会展馆中展出，并以其成功模拟中医把脉，实现中医脉诊客观化的体验效果轰动馆内，现代化的便携设计、互联网大数据与传统中医把脉相结合，让来场参观的近万名参观者和体验者为之赞叹！'
        },
        {
          order: '04',
          date: '2019/6/6',
          img: '治未病.jpg',
          title: '喜讯！三探头中医智能脉诊仪入选全国卫生产业协会治未病分会“治未病服务适宜设备”！',
          content: '2019年5月31日至6月1日，由全国卫生产业企业管理协会治未病分会主办的“2019治未病高峰论坛暨第五届中国中医药健康服务业大会”在北京圆满召开。北京帕斯泰克科技有限公司的代表携三探头中医智能脉诊仪也受邀参加了本次专家评审会，此次参与评选的企业都是来自全国实力雄厚的中医药领域企业，经过层层评选，三探头中医智能脉诊仪最终成功入选“治未病服务适宜设备”！这也是业界对帕斯泰克三探头中医智能脉诊仪在中医治未病领域做出的突出成绩给予的肯定。'
        },
        {
          order: '05',
          date: '2018/3/30',
          img: '知网.jpg',
          title: '中医智能脉诊仪助力中国知网送健康',
          content: '2018年3月22日至24日，帕斯泰克科技有限公司携手中国知网开展了一场“凝心聚力促发展、心系员工送温暖”的“爱心免费中医体检活动”。本次活动旨在通过先进的三探头中医智能脉诊仪诊脉，关注员工的身体健康，普及中医健康养生知识，让大家在工作之余多关注自己的健康；同时扩大中医智能脉诊仪的知名度和影响力，让更多的人了解目前国内智能中医诊疗的先进发展水平。'
        },
        {
          order: '06',
          date: '2017/12/11',
          img: '2017博览会.jpg',
          title: '帕斯泰克“2017国际创新创业博览会”圆满收官',
          content: '作为中国最具影响力的展会之一，本届国际创新创业博览会于2017年12月8日至10日在北京国家会议中心成功举办，展会共邀请了美国、俄罗斯、英国等20多个国家和地区的创新创业企业前来参展。帕斯泰克“三探头中医智能脉诊仪”作为年度高新技术产品之一，也受邀在本次“TOP 新技术、新产品”主题展馆中展出，并以其成功模拟中医把脉，实现中医脉诊客观化的体验效果轰动馆内，现代化的便携设计、互联网大数据与传统中医把脉相结合，让来场参观的近万名参观者和体验者为之赞叹！'
        }
      ],
      checkedItems: [],
      checkedItem: {
        order: '01',
        date: '2020/7/10',
        img: '新闻图片1.png',
        title: '祝贺三探头中医脉诊仪荣获二类医疗器械注册证',
        content: '2020年7月6日，由帕斯泰克公司自主研发的三探头中医智能脉诊仪（MS3-IV）获得由天津市药品监督管理局颁发的二类医疗器械注册证。此次收到《医疗器械注册证》的产品名为三探头中医脉诊仪，主要用于对成年人群进行人体脉诊信息采集。此次三探头中医脉诊仪《医疗器械注册证》的取得，有助于满足市场多样化的中医脉诊检测需求，进一步提升公司的核心竞争力与综合服务能力。'
      },
      activeIndex: 0,
      pagesize: 5,
      currentPage: 1
    }
  },
  computed: {
    showNews () {
      return this.news.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize)
    }
  },
  methods: {
    checkItem: function (k) {
      this.checkedItems = this.showNews.filter((item, index) => {
        if (index === k) {
          this.activeIndex = k
          return item
        }
      })
      this.checkedItem = this.checkedItems[0]
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage
      this.checkItem(0)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import './../assets/css/basic.styl'
.news
  .banner
    & img
      width 100%
      height 5.58rem
  .content
    width 13.83rem
    margin 0.93rem auto 0.62rem
    .main
      margin-bottom 2rem
      .detail
        margin-bottom 1rem
        .tit
          font-size 0.34rem
          margin-bottom 0.27rem
        .date
          font-size 0.34rem
          color #004ea2
          border-bottom 0.01rem solid #004ea2
          height 0.64rem
          line-height 0.64rem
          margin-bottom 0.36rem
        .desc
          display flex
          .text
            width 8.9rem
            line-height 0.5rem
            text-indent 2em
            margin-bottom 0.5rem
            font-size 0.23rem
          .img
            & img
              width 4.15rem
              margin-left 0.7rem
      .items
        margin-bottom 0.75rem
        .item
          display flex
          font-size 0.2rem
          cursor pointer
          .l_item
            width 1.32rem
            height 1.6rem
            border 0.01rem solid #ccc
            text-align center
            .l_top
              height 1.2rem
              line-height 1.2rem
              background #ccc
              color #fff
              font-size 0.32rem
            .l_bottom
              color #ccc
              height 0.4rem
              line-height 0.4rem
          .r_item
            width 11.86rem
            margin-left 0.55rem
            .tit
              font-size 0.3rem
              margin-bottom 0.2rem
            .g_text
              overflow hidden
              text-overflow ellipsis
              display -webkit-box
              -webkit-box-orient vertical
              -webkit-line-clamp 3
              .text
                text-indent 2em
                line-height 0.35rem
        .active
          color #004ea2
          .l_item
            border 0.01rem solid #004ea2
            .l_top
              background #004ea2
            .l_bottom
              color #004ea2
    .pagination
      text-align center
</style>
